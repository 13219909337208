.gm-style-mtc  {
    margin: 0 2px;
}
.gm-style-mtc div{
    height: 25px !important;
    font-size: 12px !important;
}
.gmnoprint div{
    width: 60px;
}
.gm-svpc{
    width: 24px !important;
    height: 27px !important;
    top: 57px !important;
    border-radius: 7px !important;
}
.gm-svpc > div > img{
    height: 18px !important;
}
.gm-bundled-control > .gmnoprint > div{
    width: 25px !important;
    height: 54px !important;
    border-radius: 7px !important;
}
.gm-bundled-control > .gmnoprint > div > button > img{
    height: 10px !important;    
}
.gm-bundled-control > .gmnoprint > div > div{
    width: 16px !important;
}
.gm-control-active {
    width: 26px !important;
    height: 28px !important;
}
.gm-bundled-control {
    right: 25px !important;
    top: 125px !important;
}