.ErrorRow td{
    border-top: 0.5px red solid;
    border-bottom: 0.5px red solid !important;
    border-right:none;
    border-left: none;
}
.ErrorRow td:nth-child(1){
    border-left: 0.5px red solid;
}
.ErrorRow td:last-child{
    border-right: 0.5px red solid;
}
.ant-upload-list-item-name{
    white-space: pre !important;
}