.ant-select-dropdown-menu
    ::-webkit-scrollbar-track {
        width: 6px;
        height:0px;
      }
    ::-webkit-scrollbar {
        width: 6px;
        height:0px;
      }
    ::-webkit-scrollbar-thumb {
        background-color: #ddd;
        border-radius: 10px;
      }

.ant-select-dropdown {
    width: 170px !important;
}
.ant-select-dropdown-menu-item-group-title {
    background-color: rgba(200,200,200,0.26);
    font-size: 14px;
    color: #212121;
}
.ant-select-dropdown-menu-item {
    font-weight: normal;
    font-size: 14px;
    color: #757575;
    background-color: white !important;
}

 .ant-select-dropdown-menu-item-selected {
     font-weight: 500;
     font-size: 14px;
     color: #212121;
     background-color: white;
}
.ant-select-dropdown-menu-item-active {
    font-weight: 500;
    font-size: 14px;
    color: #212121;
    background-color: white;
}
.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: white;
}
.ant-select-dropdown-menu-item-active {
    background-color: white;
}

.ant-popover-inner-content{
    padding: 0px;
}